export function getBackgroundImage() {
  const imageCount = 48
  const secondsInOneDay = 60 * 60 * 24
  const secondsPerImageSlot = secondsInOneDay / imageCount
  const now = new Date()
  const currentSeconds = now.getHours() * 60 * 60 + now.getMinutes() * 60 + now.getSeconds()
  const currentSlotIndex = Math.floor(currentSeconds / secondsPerImageSlot)
  // return `https://assets.xastatic.com/xlobby-live/sequence-${currentSlotIndex}.jpg`
  return {
    id: currentSlotIndex,
    url: `https://assets.xastatic.com/xlobby-live/sequence-${currentSlotIndex}.jpg`
  }
}