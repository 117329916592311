import "./App.css";
import { Statistic } from "antd";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import { useSpring, animated } from "react-spring";

import { useState, useEffect } from "react";
import axios from "axios";
import {getBackgroundImage} from "./utils/BackgroundImageManager";

const { Countdown } = Statistic;

let backgroundImageId = null

function App() {
  const [timeStr, setTimeStr] = useState(dayjs().format("HH:mm:ss"));
  const [notice, setNotice] = useState("");
  const [showCountdown, setShowCountdown] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);

  const [countDownWrapperStyle, animationApi] = useSpring(() => ({
    scaleX: 1,
    scaleY: 1,
    alignItems: "center",
    transformOrigin: "bottom left"
  }));



  useEffect(() => {
    setInterval(() => {
      setTimeStr(dayjs().format("HH:mm:ss"));
    }, 100)

    // getNotice();
    // setInterval(() => {
    //   getNotice();
    // }, 1000 * 20);
    setShowCountdown(true);

    refreshBackgroundImage();

    const clockAlignmentOffset = (60000 - Date.now() % 60000) + 30000
    const refreshSpreadRandomOffset = Math.floor(Math.random() * 60000)
    const firstClockAfter = clockAlignmentOffset + refreshSpreadRandomOffset - 60000

    console.debug('Refresh clock will start at', new Date(Date.now() + firstClockAfter), ', with clock alignment offset', clockAlignmentOffset, 'ms (to', new Date(Date.now() + clockAlignmentOffset), ') and refresh random spread offset', refreshSpreadRandomOffset, 'ms')

    setTimeout(() => {
      console.debug('Refresh clock initiated at', new Date(), '. will first refresh once and wait for interval.')
      refreshBackgroundImage()
      setInterval(() => {
        refreshBackgroundImage()
      }, 1000 * 60)
    }, firstClockAfter) // align with clock xx:xx:30, then spread refresh requests into 60 seconds
  }, []);

  function refreshBackgroundImage() {
    console.debug('Refreshing background image at', new Date().toString())

    const {url, id} = getBackgroundImage()
    console.debug('Got background URL as', url, 'id as', id, '. previous backgroundImageId:', backgroundImageId)

    if (id === backgroundImageId) {
      console.debug('background is identical. skipped update.')
    } else {
      console.debug('background is different. fetching and swapping...')

      axios
        .get(url, {
          responseType: 'blob'
        })
        .then((res) => {
          const data = res.data
          console.log('Got image response as', data)

          const blobUrl = URL.createObjectURL(data)
          console.log('Created blob url', blobUrl, '. swapping...')

          setBackgroundImage(blobUrl)
          backgroundImageId = id
        })
        .catch(err => {
          console.debug('Failed to fetch image from CDN:', err)
        });
    }
  }

  function getNotice() {
    axios
      .get("https://sigma.xacademy.cc/api/capstone")
      .then((res) => {
        setShowCountdown(res.data.showCountdown);
        if (res.data.isNotificationPublished) {
          setNotice(res.data.notification);
        } else {
          setNotice("");
          animationApi.start({
            scaleX: 1,
            scaleY: 1,
            alignItems: "center",
          });
        }
      })
      .catch(() => {
        setNotice("");
        animationApi.start({
          scaleX: 1,
          scaleY: 1,
          alignItems: "center",
        });
      });
  }
  function onFinish() {
    console.log("finished!");
  }

  const PADDING = "1.5rem"

  const notificationRender = () => {
    if (notice && notice !== "") {
      animationApi.start({
        scaleX: 0.7,
        scaleY: 0.7,
        alignItems: "center",
      });
      return (
        <div>
          <div className="xa-announcement-title" style={{ fontSize: "calc(12px + (48 - 12) * ((100vw - 150px) / (1600 - 150)))" }}>
            PASSENGER ANNOUNCEMENT IN PROGRESS
          </div>
          <ReactMarkdown children={notice} />
        </div>
      );
    }
  };

  const countdownRender = () => {
    if (showCountdown) {
      return (
        <Countdown
          valueStyle={{
            fontSize: "2.75rem",
            lineHeight: "2.75rem",
            fontFamily: "neoX Digits Monospace",
            color: "#fff",
            marginTop: ".25rem"
          }}
          value={1659963600000}
          onFinish={onFinish}
        />
      );
    }
  };

  return (
    <div className="content">
      <div className="live-background-image" style={{ backgroundImage: `url(${backgroundImage})`, opacity: backgroundImage ? 1 : 0 }} />
      <div style={{ padding: PADDING, zIndex: 10 }}>
        <div className="xa-logo" style={{ marginBottom: '1rem' }}>
          X ACADEMY
        </div>
        <div className="xa-divider-wrapper">
          {
            [...Array(6)].map(() => (
              <div className="xa-divider" />
            ))
          }
        </div>
        <div className="xa-title" style={{ fontSize: "1rem", marginTop: ".75rem" }}>
          CAPSTONE 倒计时
        </div>
        {countdownRender()}

        {notificationRender()}

        <div className="footer">
          <div className="typography">
            <div className="left">
              <div>云端</div>
              <div>&nbsp;</div>
              <div>N 22° 33' 9.2"</div>
              <div>E 114° 7' 25.0"</div>
            </div>
            <div className="right">
              <div>#置身未来</div>
              <div>X ACADEMY 2022</div>
            </div>
          </div>
          <div className="border-line" />
        </div>

        {/* LOGO组件 */}
        {/*<div*/}
        {/*  style={{*/}
        {/*    position: "fixed",*/}
        {/*    bottom: PADDING,*/}
        {/*    right: PADDING,*/}
        {/*    zIndex: 1,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <span className="font-neox-display" style={{*/}
        {/*    fontSize: "calc(36px + (72 - 36) * ((100vw - 150px) / (1600 - 150)))",*/}
        {/*    lineHeight: "calc(36px + (72 - 36) * ((100vw - 150px) / (1600 - 150)))"*/}
        {/*  }}>*/}
        {/*    X*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export default App;
